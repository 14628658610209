<template>
  <div class="grid gap-4 grid-cols-4">
    <FrontpageIconBox
      v-for="(benefit, index) in benefits"
      :key="index"
      :icon-name="benefit.icon"
      :headline="benefit.headline"
      class="col-span-full md:col-span-2 lg:col-span-1 transition-all duration-300 hover:scale-105 hover:shadow-lg"
    >
      <span v-html="benefit.description"></span>
    </FrontpageIconBox>
  </div>
</template>

<script setup>
const benefits = [
  {
    icon: "person_add",
    headline: "Ihre kostenfreie Mitgliedschaft",
    description: `Ihre Mitgliedschaft ist <strong>unverbindlich, jederzeit kündbar</strong>
        und <strong>kostenfrei</strong>. Keine Aufnahme- oder Jahresgebühren.<br /><br />
        Sie gehen keinerlei Verpflichtung ein.`,
  },
  {
    icon: "task",
    headline: "Ihre kostenfreien Abrufscheine",
    description: `Als <strong>Mitglied</strong> sind Sie berechtigt und erhalten
        <strong>alle Abrufscheine kostenfrei.</strong><br /><br />
        <span class="text-gray-600 text-xs">
            Bei Inanspruchnahme der ersten Leistung entsteht eine einmalige Datengebühr von nur 45 Euro
            netto. Weitere Kosten entstehen nicht.
        </span>`,
  },
  {
    icon: "support_agent",
    headline: "Ihr persönlicher Service-Scout Tim",
    description: `<strong>Erfahrung</strong> und <strong>Freundlichkeit</strong> trifft Verständnis und
        Professionalität. Das ist Tim, Ihr Service-Scout.<br /><br />
        Er freut sich darauf, Sie kennenzulernen.`,
  },
  {
    icon: "verified",
    headline: "Ihr Mitglieder Online-Magazin",
    description: `Verpassen Sie keine Angebote, indem Sie <strong>gratis</strong> die
        <strong>OTK insight</strong> abonnieren. Sie können die OTK insight jederzeit wieder abbestellen.`,
  },
];
</script>
