<template>
  <main>
    <FrontpageTeaser
      headline-tag="h1"
      image="startseite"
      class="xl:py-36 relative"
      title="Exklusive Sonderkonditionen für Firmenwagen"
      title-class="max-w-xl mb-2 relative z-20"
      subtitle-class="drop-shadow-[6px_6px_12px_rgba(0,0,0,1.8)]"
    >
      <div class="grid grid-cols-2 gap-8">
        <div class="col-span-full lg:col-span-1">
          <FrontpageHeadline
            level="6"
            weight="600"
            class="max-w-xl text-pretty drop-shadow-[6px_6px_12px_rgba(0,0,0,1)] mb-4"
          >
            Für Pkw & Nutzfahrzeuge. Ohne Mitgliedsbeitrag.
            <br class="hidden lg:inline" />Mit kostenfreiem Abrufschein.
          </FrontpageHeadline>

          <p class="max-w-lg text-white font-semibold drop-shadow-[6px_6px_12px_rgba(0,0,0,1)]">
            Für Gewerbetreibende, Firmenkunden, Unternehmer, Freiberufler und Selbstständige. Für
            alle Branchen.
          </p>

          <div v-if="!account" class="flex flex-wrap gap-y-2 gap-x-8 mt-8">
            <NuxtLink to="/mitglied-werden#formular">
              <FrontpageButton class="min-w-48">Mitglied werden</FrontpageButton>
            </NuxtLink>
            <FrontpageButton
              outline
              class="min-w-48 !bg-transparent hover:!bg-white/10 hover:brightness-125 !text-primary"
              @click="layout.login = true"
            >
              Login
            </FrontpageButton>
          </div>
        </div>
        <div
          class="2xl:pl-20 col-span-full lg:col-span-1 relative max-lg:hidden"
          @click="promotion = true"
        >
          <button
            class="absolute z-10 -top-8 left-36 xl:left-64 opacity-50 border-[0.7rem] size-28 flex border-white rounded-full drop-shadow-xl hover:scale-110 hover:opacity-100 transition-transform duration-150"
          >
            <div
              class="size-0 border-t-[1.75rem] border-t-transparent border-b-[1.75rem] border-b-transparent border-l-[3.5rem] border-l-white m-auto translate-x-2"
            ></div>
          </button>
        </div>
      </div>
    </FrontpageTeaser>

    <section v-animate class="section-top">
      <div class="container-sm">
        <div class="grid grid-cols-12 gap-y-8 md:gap-x-16 items-center">
          <div class="col-span-12 lg:col-span-7">
            <FrontpageHeadline level="3" class="text-balance max-w-screen-sm mb-8">
              Erst besser finden. Dann besser fahren.
            </FrontpageHeadline>

            <p class="mb-4 font-semibold">
              ERFOLG IST, WENN SIE GENAU DAS BEKOMMEN, WAS SIE WIRKLICH WOLLEN.
            </p>
            <p class="mb-4">
              Doch im Angebotsdschungel ist das leichter gesagt als getan. Unübersichtliche
              Plattformen, endlose Optionen, widersprüchliche Konditionen – und am Ende bleibt die
              Unsicherheit: „Ist das wirklich das Beste für mich?“
            </p>
            <p class="mb-4">
              Die Realität: Viele Entscheidungen entstehen unter Zeitdruck – und führen nicht selten
              zu Frust statt Fahrfreude.
            </p>
            <p class="font-semibold">Die Lösung?</p>
            <p class="mb-4">
              <strong>Der OTK Firmenwagen-Finder.</strong>
              Ihre smarte Plattform, die aus Überangebot Übersicht macht. Unabhängig. Interaktiv.
              Intuitiv.
            </p>
            <p class="">
              Sie geben an, was Sie brauchen – wir liefern das Beste. So wird aus einem
              komplizierten Prozess eine klare Entscheidung. <br />
              <strong>Erst besser finden. Dann besser fahren.</strong>
            </p>
          </div>

          <NuxtImg
            alt="Erfolg"
            src="/icons/erfolg.jpg"
            class="col-span-12 lg:col-span-5 w-full max-w-28 lg:max-w-64 max-lg:mr-auto lg:ml-auto lg:mr-0 max-lg:order-first"
          />
        </div>
      </div>
    </section>

    <section v-animate class="section-top relative">
      <FrontpageTeaser
        headline-tag="h1"
        image="firmenwagen-finder"
        class="xl:py-36 relative h-72 overflow-visible"
        title-class="max-w-xl mb-2 relative z-20"
      />

      <div class="container-lg grid gap-16 relative -mt-[13.2rem]">
        <FrontpageVehicleFinderX class="relative z-30 shadow-lg rounded-lg overflow-hidden" />
      </div>
    </section>

    <section v-if="vehicles.length" v-animate class="margin-sm">
      <div class="container-lg grid">
        <FrontpageHeadline
          level="3"
          class="text-center mx-auto text-balance max-w-screen-sm margin-bottom"
        >
          Top Deals
        </FrontpageHeadline>
        <FrontpageTopDeals :vehicles="vehicles" load-more />
      </div>
    </section>

    <section v-if="!account" v-animate class="margin-sm">
      <div class="container grid">
        <FrontpageHeadline
          level="3"
          class="text-center mx-auto text-balance max-w-screen-sm margin-bottom"
        >
          Ihre Vorteile
        </FrontpageHeadline>
        <FrontpageBenefitsGrid />
        <div class="flex flex-wrap gap-y-2 gap-x-8 justify-center mb-8 margin-top">
          <NuxtLink to="/mitglied-werden#formular">
            <FrontpageButton class="min-w-48 w-full">Mitglied werden</FrontpageButton>
          </NuxtLink>

          <FrontpageButton outline class="min-w-48" @click="layout.login = true"
            >Login</FrontpageButton
          >
        </div>
      </div>
    </section>

    <FrontpageTeaser
      title="Elektromobilität ist&#10;nicht die Zukunft –&#10;sie ist Jetzt."
      headline-tag="h1"
      image="elektro"
      class="h-[800px]"
      title-class="whitespace-pre-wrap"
    >
      <div class="relative flex flex-col gap-4">
        <div>
          <p class="font-semibold max-w-96">Der Umstieg auf Elektrofahrzeuge</p>
          <p class="text-base max-w-96">
            war nie relevanter als heute. Doch worauf müssen Sie achten? Welche Vorteile bietet die
            E-Mobilität wirklich?
            <br />
          </p>
          <p class="font-semibold max-w-96 mt-4 drop-shadow-[6px_6px_12px_rgba(0,0,0,1)]">
            Ihr umfassender
            <a
              href="https://martinguss.de/umstieg-aufs-elektroauto/"
              target="_blank"
              class="text-white underline italic hover:text-gray-200"
              >Leitfaden</a
            >
            für den erfolgreichen Einstieg – fundiert, praxisnah und verständlich.
          </p>
          <ul class="list-none mt-4">
            <li>Autor: <span class="font-semibold">Martin Guss </span></li>
            <li>Herausgeber: <span class="font-semibold">Stiftung Warentest</span></li>
          </ul>
        </div>
        <a
          href="https://martinguss.de/umstieg-aufs-elektroauto/"
          target="_blank"
          rel="noopener noreferrer"
          class="absolute right-[-260px] top-[-270px] w-[705px] h-[630px] z-50 bg-transparent"
        >
          <img
            src="/teaser/spiegel.svg"
            alt="Spiegel"
            class="absolute inset-0 w-full h-full opacity-0 mix-blend-multiply pointer-events-none"
          />
        </a>
        <Icon
          size="150"
          name="highlight_mouse_cursor"
          class="w-12 origin-bottom-right absolute right-[60px] bottom-[-105px] z-30"
        />
        <NuxtImg
          class="rotate-[5deg] w-[300px] absolute left-[370px] -bottom-[120px]"
          src="/icons/elektro-discount-banner.png"
        />
      </div>
    </FrontpageTeaser>

    <section v-animate class="section-top">
      <div class="container-sm">
        <FrontpageHeadline level="3" class="max-w-xl mb-8"> Was uns antreibt </FrontpageHeadline>
        <div class="grid grid-cols-12 gap-y-8 md:gap-x-16">
          <div class="col-span-12 md:col-span-5 max-w-md">
            <p class="mb-4 hyphens-auto">
              Wir engagieren uns leidenschaftlich für Ihren wirtschaftlichen Erfolg. Denn Ihr Erfolg
              treibt auch unser Wachstum voran.
            </p>
            <p class="hyphens-auto">
              Eine Partnerschaft, die für alle Seiten Vorteile schafft - fair, nachhaltig und mit
              einem ehrlichen Lächeln.
            </p>
          </div>

          <div class="col-span-12 md:col-span-7">
            <ul class="grid gap-8 sm:grid-cols-2 font-medium">
              <li class="flex items-center gap-4">
                <NuxtImg loading="lazy" alt="OTK Logo" src="/icons/logo-otk.svg" class="w-9" />
                <div>Seit 2017 erfolgreich im Markt</div>
              </li>
              <li class="flex items-center gap-4">
                <Icon name="groups" :weight="400" class="text-primary" :size="36" />
                <div>Über 100.000 Mitglieder</div>
              </li>
              <li class="flex items-center gap-4">
                <Icon name="hotel_class" :weight="400" class="text-primary" :size="36" />
                <div class="flex flex-col">
                  <span>
                    4,8
                    <span class="inline-flex">
                      <Icon :size="22" name="star" fill class="text-primary" />
                      <Icon :size="22" name="star" fill class="text-primary" />
                      <Icon :size="22" name="star" fill class="text-primary" />
                      <Icon :size="22" name="star" fill class="text-primary" />
                      <Icon :size="22" name="star_half" fill class="text-primary" />
                    </span>
                  </span>
                  <span class="text-sm">Kundenbewertung</span>
                </div>
              </li>
              <li class="flex items-center gap-4">
                <NuxtImg
                  loading="lazy"
                  alt="Rahmenvertrag Logo"
                  src="/icons/rahmenvertrag.svg"
                  class="w-16"
                />
                <div>Rahmenverträge mit einer Vielzahl attraktiver Marken</div>
              </li>
              <li class="flex items-center gap-4">
                <Icon name="car_tag" :weight="400" class="text-primary" :size="36" />
                <div>40+ Jahre Erfahrung in der Automobilbranche</div>
              </li>
              <li class="flex items-center gap-4">
                <NuxtImg
                  loading="lazy"
                  alt="DEHOGA Logo"
                  src="/icons/logo-dehoga.svg"
                  class="w-9"
                />
                <div>Kompetenzpartner von DEHOGA</div>
              </li>
              <li class="flex items-center gap-4">
                <NuxtImg
                  loading="lazy"
                  alt="LEASYS Logo"
                  src="/icons/logo-leasys.svg"
                  class="w-9"
                />
                <div>Kooperation mit LEASYS Full-Service-Leasing für sorgenfreie Mobilität</div>
              </li>
              <li class="flex items-center gap-4">
                <NuxtImg loading="lazy" alt="BVI Logo" src="/icons/logo-bvi.svg" class="w-9" />
                <div>Partnerschaft mit BVI</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section v-animate class="section-top">
      <div class="container grid gap-16">
        <FrontpageHeadline level="3" class="text-center mx-auto text-balance max-w-screen-sm">
          Auf die Meinung unserer Mitglieder dürfen wir stolz sein.
        </FrontpageHeadline>
        <FrontpageTestimonials />
      </div>
    </section>

    <section v-animate class="section-top">
      <div class="container-sm grid">
        <FrontpageHeadline
          level="3"
          class="text-center mx-auto text-balance max-w-screen-sm margin-sm"
        >
          Beste Marken - Beste Preise - Beste Deals
        </FrontpageHeadline>
        <FrontpageBrands />
        <div v-if="!account" class="flex flex-wrap gap-y-2 gap-x-8 justify-center margin-top">
          <NuxtLink to="/mitglied-werden#formular">
            <FrontpageButton class="min-w-48">Mitglied werden</FrontpageButton>
          </NuxtLink>
          <FrontpageButton outline class="min-w-48" @click="layout.login = true"
            >Login</FrontpageButton
          >
        </div>
      </div>
    </section>

    <section v-animate class="section-top">
      <div class="container-sm grid">
        <FrontpageHeadline
          level="3"
          class="text-center mx-auto text-balance max-w-screen-sm margin-bottom"
        >
          Q & A
        </FrontpageHeadline>
        <div class="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <template v-for="item in questions" :key="item.id">
            <FrontpageIconBox
              hover
              class="cursor-pointer"
              icon-name="contact_support"
              @click="handleModal(item)"
            >
              <div v-html="item.question"></div>
            </FrontpageIconBox>
          </template>

          <Modal v-model="modal.show">
            <Card class="m-auto w-full max-w-lg">
              <template #label>
                <span class="px-4 pb-1.5 pt-2 block" v-html="modal.question"></span>
              </template>
              <div class="px-4 py-2" v-html="modal.answer"></div>
            </Card>
          </Modal>
        </div>
        <NuxtLink
          href="/fragen-und-antworten"
          class="flex items-center group hover:text-primary duration-150 mx-auto col-span-full margin-top"
        >
          <span class="mr-2 font-medium">Weitere Fragen & Antworten</span>
          <svg
            class="size-9 fill-gray-600 group-hover:text-primary group-hover:translate-x-4 duration-500"
            viewBox="0 -960 960 960"
          >
            <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
          </svg>
          <div class="h-5 w-1 -ml-0.5 bg-primary group-hover:-translate-x-6 duration-500"></div>
        </NuxtLink>
      </div>
    </section>

    <section v-animate class="section-base">
      <div class="container-sm">
        <FrontpageContactBox />
      </div>
    </section>

    <section v-if="!account" v-animate>
      <div class="container-sm grid text-center">
        <FrontpageHeadline level="3" class="max-w-sm lg:max-w-xl text-center mx-auto">
          Mitglieder-Service
        </FrontpageHeadline>
        <p class="max-w-3xl mx-auto margin-sm">
          Bleiben Sie über unsere Angebote, Sonderkonditionen und Neuerungen mit dem Online
          Mitglieder-Magazin OTK insight immer auf dem Laufenden. Dieser Service ist kostenlos und
          Sie können ihn nach Ihrer Anmeldung abonnieren und jederzeit kündigen.
        </p>

        <div class="flex flex-wrap gap-y-2 gap-x-8 justify-center margin-bottom">
          <NuxtLink to="/mitglied-werden#formular">
            <FrontpageButton class="min-w-48 w-full">Mitglied werden</FrontpageButton>
          </NuxtLink>

          <FrontpageButton outline class="min-w-48" @click="layout.login = true"
            >Login</FrontpageButton
          >
        </div>
      </div>
    </section>

    <Modal v-model="promotion">
      <FrontpageModalPromotion v-model="promotion" src="/teaser/home-burkhard.mp4" />
    </Modal>
  </main>
</template>

<script setup>
useSeoMeta({
  title: "OTK Handelskontor für deutsche Kaufleute",
  description:
    "Exklusive Sonderkonditionen für Firmenwagen. Für Pkw & Nutzfahrzeuge. Ohne Mitgliedsbeitrag. Mit kostenfreiem Abrufschein.",
});

const { layout, account } = useStore();
const route = useRoute();

const { data } = await useFetch("/api/frontpage/site", {
  method: "POST",
  body: {
    index: 0,
    init: 6,
  },
});

const promotion = ref(false);

if (import.meta.client) {
  onMounted(() => {
    if (route.query.login === null) {
      layout.value.login = true;
    }

    const openModal = route.query.modal;
    if (openModal === "otkInsight") {
      layout.value.otkInsight = true;
    }
  });
}

const vehicles = ref([]);
const questions = ref([]);

watchEffect(() => {
  if (data.value) {
    vehicles.value = data.value.topDeals?.items || [];
    questions.value = data.value.questions || [];
  }
});

const modal = reactive({
  show: false,
  answer: "",
  question: "",
});

function handleModal(item) {
  modal.question = item.textQuestion;
  modal.answer = item.answer;
  modal.show = true;
}
</script>
